@use "bitstyles/tools/design-token";
@use "bitstyles/tools/media-query";

.sidebar {
  position: fixed;
  z-index: var(design-token.get("z-index", "top"));
  inset: 0;
  overflow: hidden;

  &.is-open {
    display: flex;
  }
}

.sidebar__close-section {
  appearance: none;
  display: block;
  flex-grow: 1;
  border: none;
  background-color: rgb(
    var(design-token.get("color", "grayscale", "black", "rgb")),
    0.2
  );
  backdrop-filter: blur(3px);
}

.sidebar__content {
  display: flex;
  flex: 0 1 80vw;
  flex-direction: column;
  height: 100%;
  padding-right: var(design-token.get("size", "s4"));
  background-color: var(design-token.get("color", "grayscale", "white"));
  box-shadow: 0 1px 1px
    rgb(var(design-token.get("color", "grayscale", "black", "rgb")), 0.16);
}

.sidebar__header {
  /* stylelint-disable custom-property-pattern */
  --bs-button--ghost-pressed-background-color: transparent;
  --bs-button--ghost-pressed-border-color: transparent;
  /* stylelint-enable custom-property-pattern */

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: var(design-token.get("size", "l6"));
  padding: var(design-token.get("size", "l4")) 0;
}

.sidebar__body {
  max-width: none;
  overflow-y: auto;
}

.sidebar__menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: calc(var(design-token.get("size", "s4")) * -1);
  margin-bottom: var(design-token.get("size", "l1"));
  padding-top: var(design-token.get("size", "s1"));
}

.sidebar__menu-profile {
  margin-bottom: var(design-token.get("size", "l4"));
}
