@use "bitstyles/tools/design-token";
@use "bitstyles/atoms/button" with (
  $allowed-shape-properties: (
    "padding-vertical",
    "padding-horizontal",
    "border-width",
    "border-radius",
    "line-height",
    "min-height",
    "min-width",
    "font-size",
    "font-weight",
    "justify-content",
  )
);

.a-button--ghost {
  text-decoration: underline;

  &:active,
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.a-button--dropdown-list {
  text-align: left;
  white-space: initial;
}

.a-button__overlay {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  inset: 0;
}

.a-button__hidden-label {
  opacity: 0;
}

.a-button__icon {
  /* prettier-ignore */
  $vertical-compensation: calc((var(design-token.get("size", "s1")) + var(design-token.get("size", "1px"))) * -1);

  margin-top: $vertical-compensation;
  margin-bottom: $vertical-compensation;
  margin-left: calc(var(design-token.get("size", "l7")) * -0.5);
}
