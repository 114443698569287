@use "bitstyles/tools/design-token";
@use "bitstyles/tools/media-query";

.suggestions-page__grid {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: var(design-token.get("size", "l3")) var(design-token.get("size", "m"));

  @include media-query.get("m") {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    row-gap: var(design-token.get("size", "l6"));
  }
}

.suggestions-page__empty-box {
  margin-bottom: var(design-token.get("size", "l7"));

  @include media-query.get("m") {
    margin-bottom: 5rem;
  }
}

.suggestions-page__edit {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(design-token.get("size", "l7"));
}

.suggestions-page__edit-date-icon {
  color: var(design-token.get("color", "pink", "2"));
}

.suggestions-page__title {
  margin-bottom: var(design-token.get("size", "l2"));
}
