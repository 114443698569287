@use "bitstyles/tools/design-token";
@use "bitstyles/tools/shadow";
@use "bitstyles/base/input-text" with (
  //
  // Base styles ////////////////////////////////////////
  $padding-vertical: var(design-token.get("size", "l3"))
    var(design-token.get("size", "s2")),
  $padding-horizontal: var(design-token.get("size", "s4")),
  $border-radius: var(design-token.get("size", "s3")),
  $border-width: var(design-token.get("size", "s5")),
  $font: inherit,
  $placeholder-font-style: italic,
  $placeholder-font-weight: var(design-token.get("font-weight", "normal")),
  $placeholder-color: var(design-token.get("color", "blue", "3")),
  //
  // Base colors ////////////////////////////////////////
  $color: var(design-token.get("color", "blue", "3")),
  $border-color: var(design-token.get("color", "grayscale", "1")),
  $background: var(design-token.get("color", "grayscale", "1")),
  $box-shadow: none,
  //
  // Hover colors ////////////////////////////////////////
  $color-hover: var(design-token.get("color", "blue", "3")),
  $background-hover: var(design-token.get("color", "grayscale", "1")),
  $border-color-hover: var(design-token.get("color", "grayscale", "1")),
  $box-shadow-hover: none,
  //
  // Active colors ////////////////////////////////////////
  $color-active: var(design-token.get("color", "blue", "3")),
  $background-active: var(design-token.get("color", "grayscale", "1")),
  $border-color-active: var(design-token.get("color", "blue", "3")),
  $box-shadow-active: none,
  //
  // Disabled colors ////////////////////////////////////////
  $color-disabled: var(design-token.get("color", "blue", "3")),
  $background-disabled: var(design-token.get("color", "grayscale", "2")),
  $border-color-disabled: var(design-token.get("color", "grayscale", "2")),
  $box-shadow-disabled: none,
  //
  // Invalid colors ////////////////////////////////////////
  // invalid colors are the same as base colors because inputs shouldn't look like they have errors
  // before the user interact with them
  $color-invalid: var(design-token.get("color", "blue", "3")),
  $background-color-invalid: var(design-token.get("color", "grayscale", "1")),
  $border-color-invalid: var(design-token.get("color", "grayscale", "1")),
  $box-shadow-invalid: none
);
