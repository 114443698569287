@use "bitstyles/tools/design-token";
@use "bitstyles/tools/media-query";

.progress-stepper {
  display: flex;
  gap: var(design-token.get("size", "s3"));
  height: var(design-token.get("size", "s4"));
  margin-bottom: var(design-token.get("size", "l1"));

  @include media-query.get("m") {
    margin-bottom: var(design-token.get("size", "l6"));
    gap: var(design-token.get("size", "m"));
  }
}

.progress-stepper__step {
  flex-basis: 1;
  flex-grow: 1;
  background-color: var(design-token.get("color", "grayscale", "3"));

  &--visited {
    background-color: var(design-token.get("color", "pink", "2"));
  }
}
