@use "bitstyles/tools/design-token";

.footer {
  background: var(design-token.get("color", "grayscale", "1"));
}

.footer__content {
  display: grid;
  grid-template-columns: 1fr 5fr;
  padding-top: var(design-token.get("size", "l3"));
  padding-bottom: var(design-token.get("size", "l3"));
  gap: var(design-token.get("size", "l6"));
}

.footer__logo {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
}

.footer__nav {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  place-content: center space-between;
  align-items: center;
  gap: var(design-token.get("size", "s5")) var(design-token.get("size", "m"));

  > * {
    white-space: nowrap;
  }
}
