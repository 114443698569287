@use "bitstyles/tools/design-token";
@use "bitstyles/tools/color";
@use "bitstyles/design-tokens/focus";
@use "bitstyles/base/input-checkbox" with (
  $border-radius: var(design-token.get("size", "s5")),
  $gap: var(design-token.get("size", "s4")),
  $border-width: var(design-token.get("size", "s5")),
  $size: var(design-token.get("size", "m")),
  $color: var(design-token.get("color", "blue", "3")),
  $background-color: var(design-token.get("color", "grayscale", "white")),
  $border-color: var(design-token.get("color", "blue", "3")),
  $color-hover: var(design-token.get("color", "blue", "3")),
  $background-color-hover: var(design-token.get("color", "grayscale", "white")),
  $border-color-hover: var(design-token.get("color", "blue", "3")),
  $color-checked: var(design-token.get("color", "grayscale", "white")),
  $background-color-checked: var(design-token.get("color", "blue", "3")),
  $border-color-checked: var(design-token.get("color", "blue", "3")),
  $background-image-checked:
    url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{color.url-encode-color(#ffffff)}' d='m83.07 11.71-44.41 44.59-21.73-21.81-15.93 15.99 37.65 37.81 60.35-60.59z'/%3E%3C/svg%3E"),
  $color-disabled: var(design-token.get("color", "blue", "3")),
  $background-color-disabled:
    var(design-token.get("color", "grayscale", "white")),
  $border-color-disabled: var(design-token.get("color", "grayscale", "3")),
  $color-disabled-checked: var(design-token.get("color", "blue", "3")),
  $background-color-disabled-checked:
    var(design-token.get("color", "grayscale", "white")),
  $border-color-disabled-checked:
    var(design-token.get("color", "grayscale", "3"))
);

[type="checkbox"] {
  transition: outline-offset
    var(design-token.get("transition", "default", "duration"))
    var(design-token.get("transition", "default", "easing"));
  outline-offset: 0;

  &:focus-visible {
    outline: focus.$outline-color solid focus.$outline-width;
    outline-offset: focus.$outline-offset;
  }
}
