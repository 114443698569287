@use "bitstyles/tools/design-token";

$color-active: var(design-token.get("color", "blue", "3"));
$border-color-active: var(design-token.get("color", "blue", "3"));
$background-color-active: var(design-token.get("color", "grayscale", "1"));
$background-image-active: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M24 11a.969.969 0 0 1 1 1 1.1 1.1 0 0 1-.281.719l-8 8a1.063 1.063 0 0 1-1.438 0l-8-8a1.063 1.063 0 0 1 0-1.438 1.063 1.063 0 0 1 1.438 0L16 18.594l7.281-7.313A1.1 1.1 0 0 1 24 11z' transform='translate(-.001)' style='fill:%23879fa8'/%3E%3C/svg%3E%0A");

@use "bitstyles/tools/color";
@use "bitstyles/base/select" with (
  $padding-vertical: var(design-token.get("size", "s2")),
  $padding-horizontal: var(design-token.get("size", "s2")),
  $border-radius: var(design-token.get("size", "s3")),
  $border-width: var(design-token.get("size", "s5")),
  $font: inherit,
  $color: var(design-token.get("color", "blue", "3")),
  $border-color: var(design-token.get("color", "grayscale", "1")),
  $background-color: var(design-token.get("color", "grayscale", "1")),
  $background-image:
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M24 11a.969.969 0 0 1 1 1 1.1 1.1 0 0 1-.281.719l-8 8a1.063 1.063 0 0 1-1.438 0l-8-8a1.063 1.063 0 0 1 0-1.438 1.063 1.063 0 0 1 1.438 0L16 18.594l7.281-7.313A1.1 1.1 0 0 1 24 11z' transform='translate(-.001)' style='fill:%2300446e'/%3E%3C/svg%3E%0A"),
  $color-hover: var(design-token.get("color", "blue", "3")),
  $border-color-hover: var(design-token.get("color", "grayscale", "1")),
  $background-color-hover: var(design-token.get("color", "grayscale", "1")),
  $background-image-hover:
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M24 11a.969.969 0 0 1 1 1 1.1 1.1 0 0 1-.281.719l-8 8a1.063 1.063 0 0 1-1.438 0l-8-8a1.063 1.063 0 0 1 0-1.438 1.063 1.063 0 0 1 1.438 0L16 18.594l7.281-7.313A1.1 1.1 0 0 1 24 11z' transform='translate(-.001)' style='fill:%23879fa8'/%3E%3C/svg%3E%0A"),
  $color-active: $color-active,
  $border-color-active: $border-color-active,
  $background-color-active: $background-color-active,
  $background-image-active: $background-image-active,
  $color-invalid: var(design-token.get("color", "grayscale", "1")),
  $border-color-invalid: var(design-token.get("color", "grayscale", "1")),
  $background-color-invalid: var(design-token.get("color", "grayscale", "1")),
  $background-image-invalid:
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M24 11a.969.969 0 0 1 1 1 1.1 1.1 0 0 1-.281.719l-8 8a1.063 1.063 0 0 1-1.438 0l-8-8a1.063 1.063 0 0 1 0-1.438 1.063 1.063 0 0 1 1.438 0L16 18.594l7.281-7.313A1.1 1.1 0 0 1 24 11z' transform='translate(-.001)' style='fill:%23879fa8'/%3E%3C/svg%3E%0A"),
  $color-disabled: var(design-token.get("color", "blue", "3")),
  $border-color-disabled: var(design-token.get("color", "grayscale", "2")),
  $background-color-disabled: var(design-token.get("color", "grayscale", "2")),
  $background-image-disabled:
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M24 11a.969.969 0 0 1 1 1 1.1 1.1 0 0 1-.281.719l-8 8a1.063 1.063 0 0 1-1.438 0l-8-8a1.063 1.063 0 0 1 0-1.438 1.063 1.063 0 0 1 1.438 0L16 18.594l7.281-7.313A1.1 1.1 0 0 1 24 11z' transform='translate(-.001)' style='fill:%23879fa8'/%3E%3C/svg%3E%0A")
);

/* stylelint-disable selector-max-type */

select {
  padding-top: var(design-token.get("size", "l3"));
  padding-bottom: var(design-token.get("size", "s4"));
  background-size: var(design-token.get("size", "l4"));

  // bitstyles overwrites focus styles with invalid styles
  &:invalid {
    &:focus,
    &:active {
      border-color: $border-color-active;
      background-color: $background-color-active;
      color: $color-active;
    }
  }
}

/* stylelint-enable selector-max-type */
