@use "bitstyles/tools/design-token";

.loading-indicator-wrapper {
  display: flex;
  padding: var(design-token.get("size", "l6"));
}

.loading-indicator-page {
  position: fixed;
  inset: 0;
  width: fit-content;
  height: fit-content;
  margin: auto;
}

.loading-indicator {
  margin: auto;
  animation: spinner-rotate 3s linear infinite;
}

.loading-indicator-circle {
  animation: spinner-dash 1.5s ease-in-out infinite;
  stroke-width: var(--spinner-stroke-width);
  stroke-linecap: round;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke: currentcolor;
  r: var(--spinner-radius);
}

.loading-indicator-size-xl {
  width: 3.5rem;
  height: 3.5rem;

  --spinner-stroke-width: 0.188rem;
  --spinner-radius: 1.25rem;
}

.loading-indicator-size-l {
  width: 2.75rem;
  height: 2.75rem;

  --spinner-stroke-width: 0.188rem;
  --spinner-radius: 1.188rem;
}

.loading-indicator-size-m {
  width: 2.25rem;
  height: 2.25rem;

  --spinner-stroke-width: 0.25rem;
  --spinner-radius: 1.125rem;
}

.loading-indicator-size-s {
  width: 2rem;
  height: 2rem;
  margin-top: -0.35rem;
  margin-bottom: -0.3rem;

  --spinner-stroke-width: 0.25rem;
  --spinner-radius: 1.125rem;
}

@keyframes spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 96, 200;
    stroke-dashoffset: -32;
  }

  100% {
    stroke-dasharray: 96, 200;
    stroke-dashoffset: -124;
  }
}
