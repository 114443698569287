@use "bitstyles/tools/design-token";
@use "bitstyles/tools/media-query";

.reservable-details__image {
  width: 100%;
  margin-bottom: var(design-token.get("size", "s2"));
  border-radius: var(design-token.get("size", "s1"));
  aspect-ratio: 4 / 3;
  object-fit: cover;
}

.reservable-details__gallery {
  display: flex;
  flex-direction: row;
  margin-top: var(design-token.get("size", "l3"));
  margin-bottom: var(design-token.get("size", "l3"));
  overflow: auto;
  gap: var(design-token.get("size", "s2"));
}

.reservable-details__gallery-item {
  flex: 0 0 20%;
  max-width: 100%;

  &:hover {
    opacity: 0.8;
  }
}

.reservable-details__gallery-item--not-selected {
  opacity: 0.2;
}

.reservable-details__gallery-image {
  width: 100%;
  aspect-ratio: 4 / 3;
  object-fit: cover;
  border-radius: var(design-token.get("size", "s4"));
}

.reservable-details__title {
  margin-bottom: var(design-token.get("size", "l3"));
  font-size: var(design-token.get("font-size", "3"));
  font-weight: var(design-token.get("font-weight", "bold"));
  line-height: var(design-token.get("line-height", "3"));

  @include media-query.get("l") {
    font-size: var(design-token.get("font-size", "5"));
    font-weight: var(design-token.get("font-weight", "black"));
    line-height: var(design-token.get("line-height", "5"));
  }
}

.reservable-details__person {
  // makes the icon look left-aligned
  margin-left: calc(-1 * var(design-token.get("size", "s3")));

  // prevent the click area of the link growing outside of its left edge because of the icon
  pointer-events: none;
}

.reservable-details__additional {
  margin-top: var(design-token.get("size", "l4"));
}

.reservable-details__additional-list {
  margin-top: var(design-token.get("size", "l4"));
  margin-left: var(design-token.get("size", "l1"));
}
