@use "bitstyles/design-tokens/layout" with (
  $sizes: (
    "0": 0,
    "1px": 0.0625rem,
    "s5": 1rem * 0.125,
    "s4": 1rem * 0.25,
    "s3": 1rem * 0.5,
    "s2": 1rem * 0.625,
    "s1": 1rem * 0.75,
    "m": 1rem,
    "l1": 1rem * 1.125,
    "l2": 1rem * 1.25,
    "l3": 1rem * 1.375,
    "l4": 1rem * 1.625,
    "l5": 1rem * 1.875,
    "l6": 1rem * 2.5,
    "l7": 1rem * 3,
    "round": 9999rem,
  ),
  $viewport-elements: (
    "html",
    "body",
    "#root",
    "#storybook-root",
  )
);
