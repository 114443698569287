@use "bitstyles/tools/design-token";
@use "bitstyles/tools/media-query";

.reservable-category__expandable-item {
  margin-top: var(design-token.get("size", "s2"));
  margin-bottom: var(design-token.get("size", "s2"));

  @include media-query.get("m") {
    margin-bottom: var(design-token.get("size", "l4"));
  }
}

.reservable-category__expandable-item-list {
  padding: var(design-token.get("size", "l1"));
}

.reservable-category__stores {
  display: flex;
  gap: var(design-token.get("size", "l3"));
  flex-wrap: wrap;
}
