@use "bitstyles/tools/color" as bs-color;
@use "sass:color";
@use "bitstyles/design-tokens/color-palette" with (
  $palette: (
    "grayscale": (
      "white": #ffffff,
      "black": #000000,
      "1": #f4f5f6,
      "2": #e3e4e6,
      "3": #b1b2b3,
      "4": #7e7c83,
    ),
    "blue": (
      "1": #879fa8,
      "2": #335266,
      "3": #00446e,
      "4": #003250,
      "5": #00263d,
    ),
    "pink": (
      "1": #f046a5,
      "2": #de1285,
    ),
    "yellow": (
      "1": #ffaf00,
    ),
  )
);
