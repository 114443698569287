@use "bitstyles/tools/design-token";
@use "bitstyles/tools/media-query";

.cancel-reservation-page__heading {
  margin-bottom: var(design-token.get("size", "l1"));
  padding-top: var(design-token.get("size", "l3"));
  border-top: 1px solid
    rgba(var(design-token.get("color", "grayscale", "3", "rgb")), 0.3);
}

.cancel-reservation-page__list {
  display: flex;
  flex-direction: column;
  margin-bottom: var(design-token.get("size", "l6"));
}

.cancel-reservation-page__link {
  align-self: flex-start;
}
