@use "bitstyles/tools/design-token";

.reservable-list-item {
  display: block;
}

.reservable-list-item__image {
  margin-bottom: var(design-token.get("size", "s2"));
  border-radius: var(design-token.get("size", "s1"));
  background-position: center;
  background-size: cover;
  aspect-ratio: 4 / 3;
}

.reservable-list-item__image--placeholder {
  background-image: linear-gradient(
    to right,
    var(design-token.get("color", "pink", "1")) -5%,
    var(design-token.get("color", "yellow", "1")) 103%
  );
}

.reservable-list-item__title {
  margin-bottom: var(design-token.get("size", "s4"));
}

.reservable-list-item__person {
  // makes the icon look left-aligned
  margin-left: calc(-1 * var(design-token.get("size", "s3")));

  // prevent the click area of the link growing outside of its left edge because of the icon
  pointer-events: none;
}
