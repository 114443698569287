@use "bitstyles/tools/design-token";

.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.error-page__header {
  display: flex;
  flex-direction: column;
  margin-bottom: var(design-token.get("size", "l6"));
  gap: var(design-token.get("size", "m"));
}
