@use "bitstyles/tools/design-token";

.select-with-label {
  position: relative;
}

.select-with-label__label {
  position: absolute;
  padding-top: var(design-token.get("size", "s4"));

  // enough padding so it doesn't overlap the arrow down icon
  padding-right: calc(var(design-token.get("size", "l6")));

  // Sum up the select input's border size and padding
  padding-left: calc(
    var(design-token.get("size", "s2")) + var(design-token.get("size", "s5"))
  );
  color: rgb(var(design-token.get("color", "blue", "4", "rgb")), 0.7);
}

.select-with-label__input {
  padding-top: var(design-token.get("size", "l3"));
  padding-bottom: var(design-token.get("size", "s4"));
  color: var(design-token.get("color", "blue", "4"));
}
