@use "bitstyles/tools/design-token";
@use "bitstyles/atoms/dropdown" with (
  $border: var(design-token.get("size", "1px")) solid
    var(design-token.get("color", "grayscale", "white")),
  $border-radius: var(design-token.get("size", "s1")),
  $box-shadow: 0 var(design-token.get("size", "s5"))
    var(design-token.get("size", "s3")) 0
    rgb(var(design-token.get("color", "grayscale", "black", "rgb")), 0.2),
  $separator-border: var(design-token.get("color", "grayscale", "3")),
  $background-color: var(design-token.get("color", "grayscale", "white")),
  $separator-spacing: var(design-token.get("size", "s5"))
);

.a-dropdown__wrapper {
  display: flex;
  position: relative;
}

.a-dropdown {
  width: 12.875rem;
  min-width: 0;
  max-height: none;
}

/* stylelint-disable no-descending-specificity */

.a-dropdown__button {
  display: flex;
  align-items: center;
  padding-right: var(design-token.get("size", "l7"));
  color: var(design-token.get("color", "blue", "4"));
  gap: var(design-token.get("size", "l5"));

  &:hover,
  &:active,
  &focus {
    .a-dropdown__icon {
      color: var(design-token.get("color", "pink", "2"));
    }
  }
}

.a-dropdown__icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

/* stylelint-enable no-descending-specificity */

.a-dropdown__list-item {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    border-bottom: 1px solid var(design-token.get("color", "grayscale", "3"));
  }
}
