@use "bitstyles/tools/design-token";
@use "bitstyles/tools/media-query";

.link-list-item {
  padding-top: var(design-token.get("size", "s2"));
  padding-bottom: var(design-token.get("size", "s2"));

  &:not(:last-child) {
    border-bottom: var(design-token.get("size", "1px")) solid
      rgb(var(design-token.get("color", "grayscale", "3", "rgb")), 0.3);
  }
}

.link-list-item__title {
  margin-right: var(design-token.get("size", "l2"));
  margin-left: var(design-token.get("size", "l2"));
  font-weight: bold;
}

.link-list-item__image {
  width: var(design-token.get("size", "l7"));
}

.link-list-item__arrow {
  margin-left: auto;
  color: var(design-token.get("color", "pink", "2"));
}

.expandable-item {
  &__icon-down {
    display: block;
  }

  &__icon-up {
    display: none;
  }

  &[open] &__icon-down {
    display: none;
  }

  &[open] &__icon-up {
    display: block;
  }
}

.expandable-item__button {
  &::-webkit-details-marker {
    display: none;
  }
}

.expandable-item__content {
  margin-right: var(design-token.get("size", "l5"));
  margin-left: var(design-token.get("size", "s2"));

  @include media-query.get("m") {
    margin-top: var(design-token.get("size", "m"));
  }
}
