@use "bitstyles/base/input-text" as input-text;

/* stylelint-disable selector-max-type */
input[type="date"] {
  appearance: none;
}

input[type="date"]:invalid {
  &::-webkit-datetime-edit-day-field,
  &::-webkit-datetime-edit-month-field,
  &::-webkit-datetime-edit-year-field {
    opacity: 0.5;
    color: input-text.$color;
    -webkit-text-fill-color: input-text.$color;
  }

  &::-webkit-datetime-edit-text {
    opacity: 1;
    color: input-text.$color;
    -webkit-text-fill-color: input-text.$color;
  }
}

input::-webkit-date-and-time-value {
  display: inline-block;
  text-align: left;
}
