@use "bitstyles/tools/design-token";
@use "bitstyles/tools/media-query";

.reservations-list {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: var(design-token.get("size", "l6"));
}

.reservations-list--items {
  gap: var(design-token.get("size", "l1"));
}

.reservations-list__title {
  margin-bottom: var(design-token.get("size", "l5"));
  font-size: var(design-token.get("font-size", "3"));

  @include media-query.get("l") {
    margin-bottom: 6rem;
    font-size: var(design-token.get("font-size", "5"));
  }
}
