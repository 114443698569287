@use "bitstyles/tools/design-token";

.dl {
  display: flex;
  flex-direction: column;
  gap: var(design-token.get("size", "s4"));
}

.dl__item {
  display: flex;
  align-items: baseline;
  gap: var(design-token.get("size", "s4"));
  overflow-wrap: break-word;
  text-wrap: pretty;
  hyphens: auto;
}

.dl__item--highlighted {
  margin-top: var(design-token.get("size", "s1"));
  padding: var(design-token.get("size", "s1")) 0;
  border-top: 1px solid
    rgba(var(design-token.get("color", "grayscale", "3", "rgb")), 0.3);

  & + & {
    margin-top: 0;
  }
}

.dl__item--stacked {
  flex-direction: column;
}

.dl__participants-list {
  margin-bottom: var(design-token.get("size", "m"));
}

.dl__icon {
  flex-shrink: 0;
  margin-left: calc(var(design-token.get("size", "s3")) * -1);
}

/* stylelint-disable selector-max-type */
dd {
  min-width: 0;
}
/* stylelint-enable selector-max-type */
