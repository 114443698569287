@use "bitstyles/tools/design-token";
@use "bitstyles/design-tokens/focus" with (
  $outline-color: var(design-token.get("color", "pink", "2")),
  $outline-width:
    calc(
      var(design-token.get("size", "s5")) + var(design-token.get("size", "s5")) /
        2
    ),
  $outline-offset: var(design-token.get("size", "s5"))
);
