@use "bitstyles/tools/design-token";

$gap-between-inputs: var(design-token.get("size", "s1"));
$gap-above-submit: var(design-token.get("size", "l2"));

.form {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: $gap-between-inputs;
  padding-bottom: var(design-token.get("size", "l2"));
}

.form__section {
  display: grid;
  grid-row-gap: $gap-between-inputs;
  grid-template-columns: minmax(0, 1fr);
  margin-bottom: var(design-token.get("size", "m"));
}

.form__row {
  display: grid;
  row-gap: var(design-token.get("size", "s4"));
}

.form__row--submit {
  padding-top: calc($gap-above-submit - $gap-between-inputs);
}

.form__label {
  // u-h4
  font-size: var(design-token.get("font-size", "3"));
  font-weight: var(design-token.get("font-weight", "bold"));
  line-height: var(design-token.get("line-height", "3"));
}

.form__error {
  padding-bottom: var(design-token.get("size", "s1"));
}

.form__input-hint {
  // u-h6
  font-size: var(design-token.get("font-size", "1"));
  line-height: var(design-token.get("line-height", "1"));
}

.form__input-hint--bigger {
  // u-h5
  font-size: var(design-token.get("font-size", "2"));
  line-height: var(design-token.get("line-height", "2"));
}

.form__input-group {
  display: grid;
  grid-column-gap: var(design-token.get("size", "s2"));
  grid-template-columns: 1fr 1fr;
}
