@use "bitstyles/tools/design-token";
@use "bitstyles/atoms/skip-link" with (
  $font-size: var(design-token.get("font-size", "4")),
  $font-weight: var(design-token.get("font-weight", "bold")),
  $padding: var(design-token.get("size", "s2"))
    var(design-token.get("size", "l3")),
  $background: var(design-token.get("color", "grayscale", "white")),
  $background-hover: #e6ebee,
  $color: var(design-token.get("color", "blue", "3")),
  $color-hover: var(design-token.get("color", "blue", "3")),
  $border: var(design-token.get("size", "s4"))
    var(design-token.get("color", "blue", "3")) solid,
  $border-radius: var(design-token.get("size", "s1")),
  $shadow: none
);
