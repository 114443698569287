@use "bitstyles/tools/design-token";
@use "bitstyles/tools/media-query";

$page-layout-breakpoint: "l";
$page-header-padding-bottom: var(design-token.get("size", "l4"));

.page-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.page-layout__top-bar {
  // reveals the top bar's bottom shadow
  position: relative;
}

.page-layout__page-header {
  padding-top: var(design-token.get("size", "l4"));
  padding-bottom: $page-header-padding-bottom;
  background-color: var(design-token.get("color", "grayscale", "1"));
}

.page-layout__main {
  flex-grow: 1;
  padding-top: var(design-token.get("size", "l5"));
  padding-bottom: var(design-token.get("size", "l5"));
  box-shadow: 0 calc(-1 * var(design-token.get("size", "s4")))
    var(design-token.get("size", "s3")) 0
    rgb(var(design-token.get("color", "grayscale", "black", "rgb")), 0.15);
}

.page-layout__page-header-content {
  display: grid;
  position: relative;
  grid-template-columns: 1fr auto;
  column-gap: var(design-token.get("size", "s3"));
}

.page-layout__page-header-navigation {
  display: grid;
  grid-column: 1 / 3;
  grid-template-columns: 1fr 1fr;
  margin-bottom: var(design-token.get("size", "s1"));

  @include media-query.get($page-layout-breakpoint) {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin-bottom: 0;
  }
}

.page-layout__back-button {
  grid-column: 1 / 2;
  justify-self: start;
  margin-left: calc(-1 * var(design-token.get("size", "s1")));

  @include media-query.get($page-layout-breakpoint) {
    margin-top: calc(-1 * var(design-token.get("size", "s5")));
    margin-left: calc(-1 * var(design-token.get("size", "s5")));
    transform: translateX(-100%);
  }
}

.page-layout__sidebar-button {
  grid-column: 2 / 3;
  justify-self: end;
  margin-right: calc(-1 * var(design-token.get("size", "s1")));
}

.page-layout__page-header-illustration {
  align-self: end;
  width: 5.5rem;
  height: auto;
  margin-bottom: calc(
    -1 * ($page-header-padding-bottom + var(design-token.get("size", "s3")))
  );

  @include media-query.get($page-layout-breakpoint) {
    width: 10rem;
    margin-bottom: calc(
      -1 * ($page-header-padding-bottom + var(design-token.get("size", "s1")))
    );
  }
}

.page-layout__page-header-title::after {
  content: "";
  display: block;

  // the size of this line (DB "pulse") must match designs exactly
  width: 4.313rem;
  height: 0.438rem;
  margin-top: var(design-token.get("size", "l4"));
  margin-bottom: var(design-token.get("size", "s2"));
  background-color: var(design-token.get("color", "pink", "1"));
  background-image: linear-gradient(
    to right,
    var(design-token.get("color", "pink", "1")) -5%,
    var(design-token.get("color", "yellow", "1")) 103%
  );
}
