@use "bitstyles/tools/design-token";
@use "bitstyles/tools/media-query";

.box {
  display: flex;
  flex-direction: column;
  padding: var(design-token.get("size", "s3"))
    var(design-token.get("size", "m")) var(design-token.get("size", "m"));
  border: 1px solid var(design-token.get("color", "grayscale", "3"));
  border-radius: var(design-token.get("size", "s1"));

  @include media-query.get("m") {
    padding: var(design-token.get("size", "m"))
      var(design-token.get("size", "l4")) var(design-token.get("size", "l4"));
  }
}

.box--empty {
  min-height: 6rem;
  padding-top: var(design-token.get("size", "l5"));
  text-align: center;
}

.box--simple {
  padding: 0;
  border: none;
}

.box__heading {
  margin-bottom: var(design-token.get("size", "s1"));
}

.box__buttons {
  display: flex;
  flex-direction: column;
  gap: var(design-token.get("size", "l1"));
}

.box__buttons-link {
  align-self: flex-start;
}
