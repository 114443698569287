@use "bitstyles/tools/design-token";

.locale-switcher {
  display: flex;
}

.locale-switcher__list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.locale-switcher__list_item {
  display: flex;
  align-items: center;

  &::after {
    content: "|";
    padding: 0 var(design-token.get("size", "s4"));
  }

  &:last-child {
    &::after {
      content: none;
    }
  }
}

.locale-switcher__button {
  &[aria-current="true"] {
    text-decoration: underline;
    text-decoration-thickness: var(design-token.get("size", "s5"));
  }
}
