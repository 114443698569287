@use "bitstyles/tools/design-token";

.top-bar {
  padding-top: var(design-token.get("size", "l3"));
  padding-bottom: var(design-token.get("size", "l3"));
  box-shadow: 0 calc(var(design-token.get("size", "1px")))
    var(design-token.get("size", "1px")) 0
    rgb(var(design-token.get("color", "grayscale", "black", "rgb")), 0.16);
}

.top-bar__nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(design-token.get("size", "l5"));
}

.top-bar__items {
  display: flex;
  gap: var(design-token.get("size", "l5"));
  flex-wrap: wrap;
}
