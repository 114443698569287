@use "sass:list";

$font-weight-normal: 400;
$font-weight-light: 300;
$font-weight-extra-light: 200;
$font-weight-bold: 600;
$webfont-family-name-head: "DBScreenHead";
$webfont-family-name-sans: "DBScreenSans";
$font-stack: -apple-system, "Helvetica", "Arial", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

@use "bitstyles/design-tokens/typography" with (
  $webfont-family-name: $webfont-family-name-sans,
  $webfont-base-url: "https://assets.bahn-x.de/fonts/latest/",
  $webfont-variants: (
    "head-black": (
      "font-family": $webfont-family-name-head,
      "font-style": normal,
      "font-weight": $font-weight-bold,
      "filename": "DBScreenHead-Black",
    ),
    "head-regular": (
      "font-family": $webfont-family-name-head,
      "font-style": normal,
      "font-weight": $font-weight-normal,
      "filename": "DBScreenHead-Black",
    ),
    "bold": (
      "font-family": $webfont-family-name-sans,
      "font-style": normal,
      "font-weight": $font-weight-bold,
      "filename": "DBScreenSans-Bold",
    ),
    "italic": (
      "font-family": $webfont-family-name-sans,
      "font-style": italic,
      "font-weight": $font-weight-normal,
      "filename": "DBScreenSans-Italic",
    ),
    "regular": (
      "font-family": $webfont-family-name-sans,
      "font-style": normal,
      "font-weight": $font-weight-normal,
      "filename": "DBScreenSans-Regular",
    ),
  ),
  $font-family-header: (
    $webfont-family-name-head,
    $font-stack,
  ),
  $font-family-body: (
    $webfont-family-name-sans,
    $font-stack,
  ),
  $line-heights: (
    // 16px/12px
    "1": 1.33,
    // 20px/14px
    "2": 1.43,
    // 24px/17px
    "3": 1.41,
    // 30px/22px
    "4": 1.36,
    // 41px/28px
    "5": 1.46,
  ),
  $font-sizes: (
    "1": 0.75rem,
    "2": 0.875rem,
    "3": 1.0625rem,
    "4": 1.375rem,
    "5": 1.75rem,
    "6": 2rem,
    "7": 2.25rem,
    "8": 2.75rem,
  )
);
